import React from "react"
import { Modal } from "reactstrap"

import "./index.scss"

const CustomModal = ({
  modalClass,
  modalStyle,
  modalType,
  fullscreen,
  backdrop,
  scrollable,
  size,
  isOpen, //boolean
  toggle, //function
  children,
}) => {
  return (
    <Modal
      className={`eon-modal eon-modal-${modalType} ${modalClass || ""}`}
      style={{ ...modalStyle }}
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      fullscreen={fullscreen}
      backdrop={backdrop}
      scrollable={scrollable}>
      {children}
    </Modal>
  )
}

export default CustomModal