import React from "react"

const CustomAvatar = ({
  containerStyle,
  imageStyle,
  imageUrl,
  imageAlt,
  imageWidth,
  imageHeight,
  onClick
}) => {
  return (
    <div
      onClick={onClick}
      style={{ display: "flex", padding: "3px 0px", cursor: 'pointer', ...containerStyle }}>
      <img
        src={imageUrl}
        alt={imageAlt || ""}
        style={{
          width: imageWidth,
          height: imageHeight,
          borderRadius: "50%",
          ...imageStyle
        }}
      />
    </div>
  )
}

export default CustomAvatar