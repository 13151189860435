import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { compose } from "redux"
import { Button } from "reactstrap"
import { Avatar } from "antd"

import CustomAvatar from "components/Avatar"
import ConfirmationModal from 'components/Modal/confirmation'

import { clearItem, getItem } from "utils/tokenStore"
import { setUserProfile } from "reducers/profile";
import { setHasApplication } from "reducers/hasApplication"
import { setCustomerProfile } from "reducers/customerProfile"

import EONLogo from 'assets/Images/eon-svg.svg'

import './index.scss'

function TopBar(props) {
  const { profile } = props.data.ProfileReducer
  const [refresh, setRefresh] = useState(false)
  const [redirectPath, setRedirectPath] = useState("")
  const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (props.data.HasApplicationReducer?.has_loan_application) {
        props.setHasApplication(false)
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [props.data.HasApplicationReducer?.has_loan_application])

  const onToggleLogOut = async () => Promise.all([
    await props.setUserProfile({}),
    await props.setCustomerProfile({}),
    clearItem("EON_ACCESS_TOKEN"),
  ]).then(() => {
    window.location.reload()
  });

  const onToggleAvatar = () => {
    if (profile?.role === "customer" && !window.location.href.includes("profile"))
      return onToggleNavigation("/dashboard/profile/setting")
    else if (profile?.role === "customer" && window.location.href.includes("profile"))
      return onToggleNavigation("/dashboard/car-booking")
    else
      return;
  }

  const onToggleNavigation = (path) => {
    if (props.data.HasApplicationReducer?.has_loan_application) {
      setRedirectPath(path)
      return setShowConfirmation(true)
    } else {
      props.history.push(path)
    }
  }

  const onToggleConfirmation = () => {
    if (refresh) {
      props.setHasApplication(false)
      setRefresh(false)
      window.reload()
    } else if (redirectPath) {
      setRedirectPath("")
      setShowConfirmation(false)
      props.setHasApplication(false)
      return props.history.push(redirectPath)
    }
  }
  
  return (
    <>
      <div className="eon-topbar-cont">
        <div className="eon-topbar-logo-cont">
          <img src={ EONLogo } alt="eon-logo" onClick={() => window.open('https://eon.com.my/')} />
          {(window.location?.href?.includes("dashboard") && profile?.role === "customer") && (
            <div className='d-flex align-items-center g-1'>
              <button
                className={`eon-topbar-navigation-btn ${window.location.href.includes("profile/applications") ? "selected" : ""}`}
                onClick={() => onToggleNavigation("/dashboard/profile/applications")}>
                My Applications
              </button>
              <button
                className={`eon-topbar-navigation-btn ${window.location.href.includes("profile/setting") ? "selected" : ""}`}
                onClick={() => onToggleNavigation("/dashboard/profile/setting")}>
                Setting
              </button>
            </div>
          )}
        </div>
        { getItem("EON_ACCESS_TOKEN") && (
          <div className="eon-topbar-cart-cont">
            <div className="d-flex align-items-center" style={{ gap: "16px" }}>
              { profile?.photo 
                ? <CustomAvatar
                    imageUrl={profile?.photo}
                    imageWidth="32px"
                    imageHeight="32px"
                    onClick={() => onToggleAvatar()} />
                : <Avatar 
                    size={32}
                    onClick={() => onToggleAvatar()}
                    children={profile?.username?.[0].toUpperCase()} />
              }
              <Button
                className="font-inter"
                style={{ color: "#ffffff" }}
                size={"sm"}
                color={"dark"}
                onClick={() => onToggleLogOut()}
                children={"Logout"}
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        children={"Exiting the application now will not save your data on this step. Are you sure you want to exit the application?"}
        leftActionText={"No"}
        rightActionText={"Yes"}
        onClickLeftAction={() => setShowConfirmation(false)}
        onClickRightAction={() => onToggleConfirmation()} />
    </>
  )
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
  connect(mapStateToProps, {
    setUserProfile,
    setHasApplication,
    setCustomerProfile,
  }), 
)(TopBar);