import React from "react"
import { ModalHeader, Button } from "reactstrap"
import { AiOutlineClose } from "react-icons/ai"

const CustomModalHeader = ({
  headerClass,
  headerStyle,
  children,
  buttonChildren,
  onButtonClick,
  close,
}) => {
  return (
    <ModalHeader
      className={headerClass || ""}
      style={{ ...headerStyle }}
      close={
        <Button
          close={close || true}
          color={"transparent"}
          size={"sm"}
        >
          {buttonChildren ? (buttonChildren) : (
            <AiOutlineClose onClick={onButtonClick} />
          )}
        </Button>
      }
    >
      {children}
    </ModalHeader>
  )
}

export default CustomModalHeader