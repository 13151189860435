import React, { lazy } from "react";

const Setting = lazy(() => import("./Setting"));
const Applications = lazy(() => import("./Applications"));
const AgentCarBooking = lazy(() => import("./AgentCarBooking"));
const ClientSelection = lazy(() => import("./ClientSelection"));

export const routes = [
  {
    path: "/car-booking",
    exact: true,
    render: (props) => <AgentCarBooking {...props} />,
  },
  {
    path: "/client-selection",
    exact: true,
    render: (props) => <ClientSelection {...props} />,
  },
  {
    path: "/profile/setting",
    exact: true,
    render: (props) => <Setting {...props} />,
  },
  {
    path: "/profile/applications",
    exact: true,
    render: (props) => <Applications {...props} />,
  }
];