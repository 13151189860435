import React from "react"
import { ModalFooter } from "reactstrap"

const CustomModalFooter = ({
  footerClass,
  footerStyle,
  children,
}) => {
  return (
    <ModalFooter
      className={footerClass || ""}
      style={{ ...footerStyle }}
    >
      {children}
    </ModalFooter>
  )
}

export default CustomModalFooter