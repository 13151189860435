import { SET_LAST_VIEW_URL } from "../actions/type";

const initialState = {
  last_view_url: "",
}

export const setLastViewURL = payload => ({
  type: SET_LAST_VIEW_URL,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_VIEW_URL:
      return {
        ...state,
        last_view_url: action.payload,
      };
    default:
      return state;
  }
}