import { SET_HAS_LOAN_APPLICARTION } from "../actions/type";

const initialState = {
  has_loan_application: false,
}

export const setHasApplication = payload => ({
  type: SET_HAS_LOAN_APPLICARTION,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HAS_LOAN_APPLICARTION:
      return {
        ...state,
        has_loan_application: action.payload,
      };
    default:
      return state;
  }
};