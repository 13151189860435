import React from "react";
import _ from "lodash";

import CustomModal from "./";
import CustomModalHeader from "./header";
import CustomModalBody from "./body";
import CustomModalFooter from "./footer";
import CustomButton from "components/Button";

const Confirmation = ({
  title,
  isOpen,
  onClose,
  children,
  leftActionText,
  rightActionText,
  onClickLeftAction,
  onClickRightAction,
}) => {
  return (
    <CustomModal isOpen={isOpen}>
      <CustomModalHeader onButtonClick={onClose}>{title || "Confirmation"}</CustomModalHeader>
      <CustomModalBody>{children}</CustomModalBody>
      <CustomModalFooter>
        <div className="d-flex" style={{ gap: "8px" }}>
          <CustomButton
            size={"sm"}
            color={"white"}
            onClick={onClickLeftAction}
          >
            {leftActionText || "Cancel"}
          </CustomButton>
          <CustomButton
            size={"sm"}
            color={"black"}
            onClick={onClickRightAction}
          >
            {rightActionText || "Save"}
          </CustomButton>
        </div>
      </CustomModalFooter>
    </CustomModal>
  );
};

export default Confirmation;
