import { combineReducers } from "redux"

import ThemeOptions from "./ThemeOptions"
import AjaxReducer from "./ajax"
import PathReducer from "./path"
import ProfileReducer from "./profile"
import EmploymentReducer from "./employment"
import LastViewURLReducer from "./lastViewURL"
import HasApplicationReducer from "./hasApplication"
import CustomerProfileReducer from "./customerProfile"

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  PathReducer,
  ProfileReducer,
  EmploymentReducer,
  LastViewURLReducer,
  HasApplicationReducer,
  CustomerProfileReducer,
})
