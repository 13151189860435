import React from "react";
import { Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import _ from "lodash";

import CustomIcon from "components/Icon";
import "./index.scss";

const FormInput = ({
  formGroupStyle,
  formGroupClass,
  type,
  row,
  id,
  step,
  labelCol,
  inputCol,
  style,
  value,
  label,
  pattern,
  disabled,
  errors,
  labelClass,
  context,
  required,
  maxLength,
  placeholder,
  onChangeData,
  labelChildren,
  inputClass,
  onClick,
  prefix,
  onKeyDown,
  instruction,
  toggleIcon,
  showToggleButton,
  inputSize
}) => {

  const errorMessage = errors?.length > 0
    ? _.find(errors, (obj) => obj.field === context)?.message
    : ''

  const renderInput = () => (
    <>
      <InputGroup className="custom-input-group">
        { prefix && <span className="custom-input-prefix">{prefix}</span> }
        <Input
          id={id}
          step={step}
          type={type}
          disabled={disabled}
          value={value}
          style={style}
          pattern={pattern}
          maxLength={maxLength}
          className={`custom-form-input ${inputClass || ""} ${inputSize ? `custom-form-input-${inputSize}` : ""} ${errorMessage ? "custom-form-input-error" : ""}`}
          placeholder={placeholder || ""}
          onChange={e => onChangeData(e.target.value)}
          onKeyDown={e => onKeyDown && onKeyDown(e)}
        />
        {
          showToggleButton && (
            <button
              className="toggle-button"
              onClick={onClick}
              onMouseDown={(e) => e.preventDefault()}>
              {toggleIcon || <CustomIcon custom="dots-horizontal" />}
            </button>
          )
        }
      </InputGroup>
      { instruction && instruction}
      <span className="text-danger" style={{ fontSize: 12 }}>{errorMessage}</span>
    </>
  )

  return (
    <FormGroup
      style={{ ...formGroupStyle }}
      className={formGroupClass || ""}
      row={row || false}
      required={required}>
      {
        label !== undefined && (
          <Label
            {...(row ? labelCol : {})}
            className={`custom-form-label ${labelClass || ""}`}
            for={context}>
            {label}
            {required ? <span className="text-danger">*</span> : ""}
            {labelChildren}
          </Label>
        )
      }
      {row ? (
        <Col {...(row && inputCol)}>
          { renderInput() }
        </Col>
      ) : ( renderInput() )}
    </FormGroup>
  );
};

export default FormInput;