import React from "react"
import { ModalBody } from "reactstrap"

import "./index.scss"

const CustomModalBody = ({
  bodyClass,
  bodyStyle,
  children,
}) => {
  return (
    <ModalBody
      className={bodyClass || ""}
      style={{ padding: "16px", ...bodyStyle }}
    >
      {children}
    </ModalBody>
  )
}

export default CustomModalBody