import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import LoadingOverlay from "components/Loading"
import TopBar from "components/TopBar"
import Breadcrumb from "components/Breadcrumb"

import { routes } from "./router";
import { getItem } from "utils/tokenStore";
import { getProfile } from "actions/profile";
import { setLastViewURL } from "reducers/lastViewURL";

const PrefixURL = "/dashboard";

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
);

class Dashboard extends Component {
  componentDidMount = () => {
    if( !getItem( 'EON_ACCESS_TOKEN' )) {
      if (this.props.location.pathname?.includes('/car-booking')){
        this.props.setLastViewURL({ pathname: this.props.location.pathname, search: this.props.location.search });
      }
      this.props.history.push( '/login' );
    } else if (this.props.data.LastViewURLReducer.last_view_url && this.props.data.ProfileReducer.profile.role === 'customer') {
      this.props.history.push(this.props.data.LastViewURLReducer.last_view_url);
    }
  };

  render = () => {
    return (
      <div className="app-main">
        <div className="app-main__outer">
          <div className="app-main__inner" style={{ padding: "0px" }}>
            <TopBar {...this.props} />
            <Breadcrumb pathData={[]} />
            <div className="eon-main-inner">
              {
                routes.map((route, index) => (
                  <SuspenseWrapper key={index}>
                    <Route
                      exact={route.exact}
                      path={`${PrefixURL}${route.path}`}
                      render={(props) => route.render(props)} />
                  </SuspenseWrapper>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps, {
  setLastViewURL,
  getProfile,
  withRouter,
})(Dashboard);
