import React, { useEffect, useState } from "react"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { connect } from 'react-redux'

import "./index.scss"

const CustomBreadcrumb = ({
  styles,
  pathData,
  data
}) => {
  const { currentState } = data.PathReducer
  const [dataPath, setDataPath] = useState([])

  useEffect(() => {
    setDataPath(currentState || pathData)
  }, [currentState, pathData])

  return (
    <Breadcrumb>
      { dataPath.map((item, index) => (
        <BreadcrumbItem key={index}>
          <div
            style={{
              fontWeight: index === pathData.length + 1 ? "bold" : "normal",
              color: index === pathData.length + 1 ? "#344054" : "#667085",
              cursor: item.onClick ? "pointer" : "default"
            }}>
            <span 
              style={{ ... styles  }}
              onClick={ () => item.onClick ? item.onClick() : {}}>
              {item.label}
            </span>
          </div>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

const mapStateToProps = (state) => ({ data: state })
export default connect(mapStateToProps)(CustomBreadcrumb)
